import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet'
import PageWrapper from '../components/PageWrapper'
import '../less/tos.less'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'

export default function TOS (props) {
  return (
    <PageWrapper id="tos">
      <HeadMeta {...props} />
      <Container>
        <Typography variant="h1" gutterBottom>Terms of Service</Typography>
        <Typography>This agreement describes the conditions and rules under which we offer you services at <i>fastimagemin.com</i>.</Typography>
        <Terms {...props} />
        <Privacy {...props} />
      </Container>
    </PageWrapper>
  )
}

function Terms (props) {
  return (
    <section id="terms">
      <Typography variant="h3" component="h2" gutterBottom>Terms</Typography>
      <Typography>By using our services, you agree to the following terms</Typography>
      <ol>
        <Typography component="li">Use our services at your own risk.</Typography>
        <Typography component="li">You bear full responsibility for the content and legality of the images and materials you are using with our services.</Typography>
        <Typography component="li">You may use our services at <i>fastimagemin.com</i> for personal or commercial purposes.</Typography>
        <Typography component="li">You agree not to claim our services your own.</Typography>
        <Typography component="li">We do not provide any guarantees to our services.</Typography>
        <Typography component="li">You agree not to sue us, if anything happened to you or your materials when using our services.</Typography>
        <Typography component="li">We reserve the right to modify or stop any of our services at any time.</Typography>
        <Typography component="li">We reserve the right to change our terms of service without notice.</Typography>
      </ol>
    </section>
  )
}

function Privacy (props) {
  return (
    <section id="privacy">
      <Typography variant="h3" component="h2" gutterBottom>Privacy</Typography>
      {/* <Typography gutterBottom>When using our services at <i>fastimagemin.com</i>, generally we do not keep your images in our server. We only collect minimum data in order to improve our services, which may includes some metadata from your requests & images, and the use of cookies. To know more about cookies, you can visit <a rel="nofollow noopener noreferrer" href="https://www.allaboutcookies.org/" target="_blank">allaboutcookies.org</a>. By using this site, you consent to our use of cookies and how we collect the required data.</Typography> */}

      <Typography gutterBottom>At <i>fastimagemin.com</i>, we valued your privacy. We never keep your images in our server or in any third party location. Every processed image would be gone as the application closes if you don&apos;t save it. This will ensure that only you have the images of yours.</Typography>
      <br />
      <Typography>We only collect minimum data in order to improve our services. This might imply the use of standard cookies. To know more about cookies, you can visit <a rel="nofollow noopener noreferrer" href="https://www.allaboutcookies.org/" target="_blank">allaboutcookies.org</a>. By using this site, you consent to our use of cookies.</Typography>
      {/* The processed images might be discarded when the session is ended, for example when closing the application, browser tab, or hitting the Clear button. */}
    </section>
  )
}

function HeadMeta (props) {
  return (
    <Helmet defer={false}>
      <title>Terms of Service with us | FastImageMin</title>
    </Helmet>
  )
}
